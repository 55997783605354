<template>
  <v-input
    v-if="visible"
    v-model="value"
    :error-messages="meta.dirty || meta.touched ? errors : []"
    @blur="handleBlur"
  >
    <v-field
      v-bind="$attrs"
      :label="label"
    >
      <template #append-inner>
        <mtf-field-icon
          :meta="meta"
          :has-errors="hasErrors"
        />
      </template>

      <div class="w-100">
        <v-toolbar
          class="w-100"
          density="compact"
          rounded
          color="transparent"
        >
          <v-spacer />
          <v-slide-x-reverse-transition leave-absolute>
            <v-chip
              v-if="hasErrors()"
              class="mt-2"
              color="error"
              label
            >
              <v-icon
                start
                icon="mdi-alert-circle-outline"
              />
              Contains Errors
            </v-chip>
          </v-slide-x-reverse-transition>
        </v-toolbar>

        <v-combobox
          v-model="selected"
          :items="useSafeValue(items, [])"
          clearable
          @update:model-value="handleSelected"
        >
          <template #append-inner>
            <mtf-field-icon />
          </template>
        </v-combobox>

        <!-- the dependent uischema will be rendered here -->
        <v-card
          v-if="dependentUischema && dependentSchema"
          flat
          border
          class="mb-4"
        >
          <v-toolbar
            flat
            density="compact"
            color="transparent"
            :title="useValues(rowLabel, `Details`, get(selected, itemTitle))"
          />

          <v-expand-transition>
            <v-card-text class="my-n4">
              <div
                v-for="field in dependentUischema.fields"
                :key="`item-${field.name}`"
                class="my-4"
              >
                <component
                  :is="is(field, getFieldSchema(field.name, dependentSchema), value)"
                  v-bind="bind(field, dependentSchema, value)"
                  :name="`${name}.${field.name}`"
                />
              </div>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </div>
    </v-field>
  </v-input>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useMatterificField } from '#imports';
import { components } from '@matterific/composables/useMatterificFields';
import { includes, get, find } from 'lodash-es';
import { useValues, useSafeValue, useModelBuilder } from '@matterific/utils';
import MtfFieldIcon from '../FieldIcon.vue';

export default defineComponent({
  name: 'MtfFieldDependent',
  components: { ...components, MtfFieldIcon },
  inheritAttrs: true,
  customOptions: {},
  // ----------------
  props: {
    schema: { type: Object, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    visible: { type: Boolean, default: true },
    // ---
    items: { type: [Array, Function], required: true },
    itemValue: { type: String, default: 'value' },
    itemTitle: { type: String, default: 'title' },
    rowLabel: [String, Function, Object, Array]

    // ---
    // TODO: alow variants like dropdown, radio, grid, etc
  },
  //emits: ['update:modelValue', 'input',],

  // ----------------

  setup(props, context) {
    const {
      bind,
      errors,
      getFieldSchema,
      handleBlur,
      hasErrors,
      is,
      isDisabled,
      isVisible,
      meta,
      model,
      validate,
      value
    } = useMatterificField(props.name, props.schema, context);

    // the selected oneOf item
    // use the property @type to determine the selected item
    // @type is a constant property on the dependent field schema,

    const selectedItem = find(
      props.items,
      (item) => get(item, props.itemValue) == get(value.value, ['@type'])
    );

    const selected = ref(selectedItem);

    const dependentName = computed(() => {
      return get(selected.value, props.itemValue);
    });

    const dependentSchema = computed(() => {
      return getFieldSchema(dependentName.value, props.schema);
    });

    const dependentUischema = computed(() => {
      return selected.value?.uischema;
    });

    function handleSelected() {
      // todo: fire a confirm dialog if the user is changing the selected item
      // todo: maybe keep a history of the selected items to allow the user to undo the change
      const model = useModelBuilder(dependentSchema.value?.properties);
      model.isOpen = true;
      value.value = model; // reset the value, ensure is an object, todo: build the model
    }

    return {
      is,
      bind,
      isVisible,
      isDisabled,

      meta,
      value,
      selected,
      errors,

      dependentSchema,
      dependentUischema,

      getFieldSchema,
      handleSelected,
      handleBlur,
      validate,
      hasErrors,
      model,

      useValues,
      useSafeValue,
      includes,
      get
    };
  },
  computed: {}
});
</script>
